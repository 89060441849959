import DomainDataModelDto from "@models/PBB/DomainDataModelDto";
import DataModelNodeQueryError from "@models/errors/DataModelNodeQueryError";
import {
   DataModelNodeArrayLengthType,
   DataModelNodeMetaData,
   DataModelNodeType,
   DataModelContentBrickFieldLink,
   IDataModelNode,
   ProjectDataModelNodeType,
   GeneralDataModelDefinitionNestedReference,
   IProjectDataModelNode,
   ContentBrickType,
} from "@backend/api/pmToolApi";
import { camelCase, findLastIndex, isArray, uniq } from "lodash";
import { Guid } from "guid-typescript";
import TreeviewUtils from "@utils/TreeviewUtils";
import PropagatedGdmReferenceFlag from "@models/dataModel/PropagatedGdmReferenceFlag";
class DataModelUtils {
   public readonly isPropagatedProperty: string = "isPropagated";
   public readonly isDisabledProperty: string = "isDisabled";
   public readonly keyProperty: string = "key";

   // TODO deprecated, remove when all mocks do not use it
   /**
    * DEPRECATED, remove when all mocks do not use it
    */
   public flatten(items: DomainDataModelDto[]): DomainDataModelDto[] {
      const flat: DomainDataModelDto[] = [];

      items.forEach((item) => {
         flat.push(item);
         if (item.children && item.children.length > 0) {
            flat.push(...this.flatten(item.children));
         }
      });

      return flat;
   }

   // TODO deprecated, remove when all mocks do not use it
   /**
    * DEPRECATED, remove when all mocks do not use it
    */
   public findTreeItem(node: DomainDataModelDto, id: number): DomainDataModelDto | null {
      if (!node) {
         return null;
      }

      if (node.id == id) {
         return node;
      }

      for (var child of node.children) {
         const foundNode = this.findTreeItem(child, id);
         if (foundNode !== null) {
            return foundNode;
         }
      }

      return null;
   }

   /**
    * Flattens the given array of nested DataModel node trees into a single flat array of DataModel nodes
    * @description Iterates the array of nodes and then recursively adds nodes to the result array
    * @param items Array of nested DataModel node trees
    * @returns Flat array of DataModel nodes of all trees
    */
   public flattenTree(items: IDataModelNode[]): IDataModelNode[] {
      const flat: IDataModelNode[] = [];

      items.forEach((item) => {
         flat.push(item);
         if (item.children && item.children.length > 0) {
            flat.push(...this.flattenTree(item.children));
         }
      });

      return flat;
   }

   /**
    * Finds the DataModel node by its ID
    * @param node DataModel tree node where to begin the search
    * @param id ID of the node to find
    * @returns Found node of the tree with the given ID, or null otherwise
    */
   public findTreeNode(node: IDataModelNode, id: string): IDataModelNode | null {
      var found = this.findTreeNodeByCondition(node, (node) => node.id === id);
      return found?.foundNode ?? null;
   }

   /**
    * Finds the DataModel node by its ID with its depth
    * @param node DataModel tree node where to begin the search
    * @param id ID of the node to find
    * @returns Found node of the tree with the given ID and its depth, or null otherwise
    */
   public findTreeNodeWithDepth(node: IDataModelNode, id: string): { foundNode: IDataModelNode; depth: number } | null {
      var found = this.findTreeNodeByCondition(node, (node) => node.id === id);
      return found;
   }

   /**
    * Finds the DataModel node and its parent by its ID
    * @param node DataModel tree node where to begin the search
    * @param id ID of the node to find
    * @returns Found node of the tree with the given ID and its parent node, or null otherwise
    */
   public findTreeNodeWithParent(
      node: IDataModelNode,
      id: string
   ): { foundNode: IDataModelNode; parentNode: IDataModelNode | null; depth: number } | null {
      var found = this.findTreeNodeByCondition(node, (node) => node.id === id);
      return found;
   }

   /**
    * Finds the DataModel node by provided condition
    * @param node DataModel tree node where to begin the search
    * @param condition Condition, which is necessary to satisfy to find the node, it must take one parameter - DataModel node
    * @param maxDepth Maximum sub-tree depth in which to search for the node (null means no maximum depth -> default)
    * @returns Found node of the tree which satisfies the given condition, or null otherwise
    */
   public findTreeNodeByCondition(
      node: IDataModelNode,
      condition: (node: IDataModelNode) => boolean,
      maxDepth: number | null = null
   ): { foundNode: IDataModelNode; parentNode: IDataModelNode | null; depth: number } | null {
      return this.findTreeNodeByConditionReq(node, condition, 0, null, maxDepth);
   }

   /**
    * Finds the DataModel node by provided condition
    * @param node DataModel tree node where to begin the search
    * @param condition Condition, which is necessary to satisfy to find the node, it must take one parameter - DataModel node
    * @param currentDepth Current depth of the recursion within the tree
    * @param maxDepth Maximum sub-tree depth in which to search for the node (null means no maximum depth -> default)
    * @returns Found node of the tree which satisfies the given condition, or null otherwise
    */
   public findTreeNodeByConditionReq(
      node: IDataModelNode,
      condition: (node: IDataModelNode) => boolean,
      currentDepth: number,
      currentParent: IDataModelNode | null,
      maxDepth: number | null = null
   ): { foundNode: IDataModelNode; parentNode: IDataModelNode | null; depth: number } | null {
      if (!node || !condition) {
         return null;
      }

      if (maxDepth !== null && currentDepth >= maxDepth) {
         return null;
      }

      if (condition(node)) {
         return { foundNode: node, parentNode: currentParent, depth: currentDepth };
      }

      for (var child of node.children) {
         const found = this.findTreeNodeByConditionReq(child, condition, currentDepth + 1, node, maxDepth);
         if (found !== null) {
            return found;
         }
      }

      return null;
   }

   /**
    * Finds the parent DataModel node of the node with the given ID
    * @param node DataModel tree node where to begin the search
    * @param id ID of the node of which to find the parent node
    * @returns Found parent node of the node with the given ID, or null otherwise
    */
   public findTreeParentNode(node: IDataModelNode, id: string): IDataModelNode | null {
      if (!node) {
         return null;
      }

      if (node.children.findIndex((ch) => ch.id === id) !== -1) {
         return node;
      }

      for (var child of node.children) {
         const foundNode = this.findTreeParentNode(child, id);
         if (foundNode !== null) {
            return foundNode;
         }
      }

      return null;
   }

   /**
    * Finds sibling DataModel nodes of the node with the given ID
    * @param rootNode DataModel tree node where to begin the search
    * @param id ID of the node of which to find the sibling nodes
    * @returns Found sibling nodes of the node with the given ID, or null otherwise
    */
   public findTreeSiblingNodes(rootNode: IDataModelNode, id: string): IDataModelNode[] | null {
      var parentNode = this.findTreeParentNode(rootNode, id);
      return parentNode?.children ?? null;
   }

   /**
    * Finds the DataModel node by provided find condition, also collects nearest ancestor node satisfying nearest node condition
    * @param node DataModel tree node where to begin the search
    * @param findNodeCondition Condition, which is necessary to satisfy to find the node, it must take one parameter - DataModel node
    * @param findNearestAncestorCondition Condition, which is necessary to satisfy collecting nearest ancestor node, it must take one parameter - DataModel node
    * @param maxDepth Maximum sub-tree depth in which to search for the node (null means no maximum depth -> default)
    * @returns Found node of the tree which satisfies the given condition, or null otherwise
    */
   public findTreeNodeWithNearestAncestorByCondition(
      node: IDataModelNode,
      findNodeCondition: (node: IDataModelNode) => boolean,
      findNearestAncestorCondition: (node: IDataModelNode) => boolean,
      maxDepth: number | null = null
   ): {
      foundNode: IDataModelNode;
      parentNode: IDataModelNode | null;
      nearestAncestor: IDataModelNode | null;
      depth: number;
   } | null {
      return this.findTreeNodeWithNearestAncestorByConditionReq(
         node,
         null,
         findNodeCondition,
         findNearestAncestorCondition,
         0,
         null,
         maxDepth
      );
   }

   /**
    * Finds the DataModel node by provided find condition, also collects nearest ancestor node satisfying nearest node condition
    * @param node DataModel tree node where to begin the search
    * @param nearestNode DataModel ancestor node satisfying nearest node condition
    * @param findNodeCondition Condition, which is necessary to satisfy to find the node, it must take one parameter - DataModel node
    * @param findNearestAncestorCondition Condition, which is necessary to satisfy collecting nearest ancestor node, it must take one parameter - DataModel node
    * @param currentDepth Current depth of the recursion within the tree
    * @param maxDepth Maximum sub-tree depth in which to search for the node (null means no maximum depth -> default)
    * @returns Found node of the tree which satisfies the given condition, or null otherwise
    */
   public findTreeNodeWithNearestAncestorByConditionReq(
      node: IDataModelNode,
      nearestAncestor: IDataModelNode | null,
      findNodeCondition: (node: IDataModelNode) => boolean,
      findNearestAncestorCondition: (node: IDataModelNode) => boolean,
      currentDepth: number,
      currentParent: IDataModelNode | null,
      maxDepth: number | null = null
   ): {
      foundNode: IDataModelNode;
      parentNode: IDataModelNode | null;
      nearestAncestor: IDataModelNode | null;
      depth: number;
   } | null {
      if (!node || !findNodeCondition || !findNearestAncestorCondition) {
         return null;
      }

      if (maxDepth !== null && currentDepth >= maxDepth) {
         return null;
      }

      var nearestAncestorLocal = nearestAncestor;
      if (findNearestAncestorCondition(node)) {
         nearestAncestorLocal = node;
      }

      if (findNodeCondition(node)) {
         return {
            foundNode: node,
            parentNode: currentParent,
            nearestAncestor: nearestAncestorLocal,
            depth: currentDepth,
         };
      }

      for (var child of node.children ?? []) {
         const found = this.findTreeNodeWithNearestAncestorByConditionReq(
            child,
            nearestAncestorLocal,
            findNodeCondition,
            findNearestAncestorCondition,
            currentDepth + 1,
            node,
            maxDepth
         );
         if (found !== null) {
            return found;
         }
      }

      return null;
   }

   /**
    * Checks whether or not there are any nodes with the same name on the same tree level, and marks them with "isDuplicate" value
    * @param rootNode DataModel tree node where to begin the search (for node's siblings, if no parent node is provided)
    * @param vue Vue instance to use for setting the values to objects (when calling from a component, usually pass 'this')
    * @param node Node of which to use its siblings as the same level
    * @param parent Parent node of which to use its children as the same level
    */
   public markDupliciteNodesOnSameLevel(
      rootNode: IDataModelNode,
      vue: Vue,
      node?: IDataModelNode,
      parent?: IDataModelNode
   ) {
      this.markDupliciteNodesOnSameLevelByProperty(rootNode, (node) => node.name, vue, node, parent);
   }

   /**
    * Checks whether or not there are any nodes with the same identifier on the same tree level, and marks them with "isDuplicate" value
    * @param rootNode DataModel tree node where to begin the search (for node's siblings, if no parent node is provided)
    * @param vue Vue instance to use for setting the values to objects (when calling from a component, usually pass 'this')
    * @param node Node of which to use its siblings as the same level
    * @param parent Parent node of which to use its children as the same level
    */
   public markDupliciteNodesOnSameLevelByIdentifier(
      rootNode: IDataModelNode,
      vue: Vue,
      node?: IDataModelNode,
      parent?: IDataModelNode
   ) {
      this.markDupliciteNodesOnSameLevelByProperty(rootNode, (node) => node.identifier, vue, node, parent);
   }

   /**
    * Checks whether or not there are any nodes with the same property on the same tree level, and marks them with "isDuplicate" value
    * @param rootNode DataModel tree node where to begin the search (for node's siblings, if no parent node is provided)
    * @param propertySelector Function to get the property from the object to check for duplicates
    * @param vue Vue instance to use for setting the values to objects (when calling from a component, usually pass 'this')
    * @param node Node of which to use its siblings as the same level
    * @param parent Parent node of which to use its children as the same level
    */
   public markDupliciteNodesOnSameLevelByProperty(
      rootNode: IDataModelNode,
      propertySelector: (node: IDataModelNode) => string | undefined,
      vue: Vue,
      node?: IDataModelNode,
      parent?: IDataModelNode
   ) {
      if (!node && !parent) return;

      var siblings: IDataModelNode[] | null = null;
      if (parent) {
         siblings = parent.children ?? [];
      } else if (node) {
         siblings = this.findTreeSiblingNodes(rootNode, node.id);
      }

      if (!siblings) return;

      for (var sibling of siblings) {
         if (this.isNodePropertyDuplicate(sibling, siblings, propertySelector)) {
            vue.$set(sibling, "isDuplicate", true);
         } else {
            vue.$set(sibling, "isDuplicate", false);
         }
      }
   }

   /**
    * Checks whether or not is the node property duplicate (2 or more instances), among provided set of nodes
    * @param node Node of which to check property duplicates
    * @param nodes List of nodes, among which to check node property duplicates
    * @param propertySelector Function to get the property from the object to check for duplicates
    * @returns True if the node property is duplicate in the list, false otherwise
    */
   public isNodePropertyDuplicate<TNode extends { name: string | undefined; children: TNode[] | undefined }>(
      node: TNode,
      nodes: TNode[] | undefined,
      propertySelector: (node: TNode) => string | undefined
   ): boolean {
      if (!nodes) return false;

      var propertyValueToFind = propertySelector(node);

      return this.containsNodePropertyValue(propertyValueToFind, nodes, 2, propertySelector);
   }

   /**
    * Checks whether or not is the node name is present (1 or more instances) among provided set of nodes
    * @param nodeName Node name to check if is present in the list
    * @param nodes List of nodes, among which to check if the node name is present
    * @returns True if the node name is present in the list, false otherwise
    */
   public isNodeNamePresent<
      TNode extends {
         name: string | undefined;
         children: TNode[] | undefined;
      },
   >(nodeName: string | undefined, nodes: TNode[] | undefined): boolean {
      return this.isNodePropertyPresent(nodeName, nodes, (node) => node.name);
   }

   /**
    * Checks whether or not is the node identifier is present (1 or more instances) among provided set of nodes
    * @param nodeName Node identifier to check if is present in the list
    * @param nodes List of nodes, among which to check if the node identifier is present
    * @returns True if the node identifier is present in the list, false otherwise
    */
   public isNodeIdentifierPresent<
      TNode extends {
         name: string | undefined;
         identifier: string | undefined;
         children: TNode[] | undefined;
      },
   >(nodeIdentifier: string | undefined, nodes: TNode[] | undefined): boolean {
      return this.isNodePropertyPresent(nodeIdentifier, nodes, (node) => node.identifier);
   }

   /**
    * Checks whether or not is the node property value is present (1 or more instances) among provided set of nodes
    * @param nodeName Node property value to check if is present in the list
    * @param nodes List of nodes, among which to check if the node property value is present
    * @param propertySelector Function to get the property from the object
    * @returns True if the node property value is present in the list, false otherwise
    */
   public isNodePropertyPresent<
      TNode extends {
         name: string | undefined;
         children: TNode[] | undefined;
      },
   >(
      propertyValue: string | undefined,
      nodes: TNode[] | undefined,
      propertySelector: (node: TNode) => string | undefined
   ): boolean {
      if (!nodes) return false;
      return this.containsNodePropertyValue(propertyValue, nodes, 1, propertySelector);
   }

   /**
    * Checks whether or not the given list of nodes contains the node with the given property value, at least minimum count times
    * @param nodePropertyValue Node property value to check if is present in the list
    * @param nodeList List of nodes, among which to check if the node property value is present
    * @param minimumCount Minimum count of nodes that have the given node property value
    * @param propertySelector Function to get the property from the object to check for duplicates
    * @returns True if the node property value is present in the list, false otherwise
    */
   private containsNodePropertyValue<
      TNode extends {
         name: string | undefined;
         children: TNode[] | undefined;
      },
   >(
      nodePropertyValue: string | undefined,
      nodeList: TNode[] | undefined,
      minimumCount: number,
      propertySelector: (node: TNode) => string | undefined
   ): boolean {
      if (!nodeList) return false;
      return nodeList.filter((x) => propertySelector(x) === nodePropertyValue).length >= minimumCount;
   }

   /**
    * Checks whether or not the data model tree node has "identifier" field, if not, fill with generated camelCase from "name"
    * @param rootNode DataModel tree node where to begin
    * @param vue Vue instance to use for setting the values to objects (when calling from a component, usually pass 'this')
    */
   public keepOrAddDataModelNodeIdentifiers(rootNode: IDataModelNode | undefined) {
      this.keepOrAddDataModelNodeIdentifiersRec(rootNode);
   }

   private keepOrAddDataModelNodeIdentifiersRec(currentNode: IDataModelNode | undefined) {
      if (!currentNode) return;

      if (
         !currentNode.identifier &&
         currentNode.name &&
         currentNode.type !== DataModelNodeType.LibraryDataModelNode &&
         currentNode.isLibraryDataModelNodeReference !== true
      ) {
         // try to pre-fill identifier by generating via node's name, unless node is GDM reference
         currentNode.identifier = this.generateNodeIdentifier(currentNode.name); // camelCase for DM definitions
      }

      if (currentNode.children) {
         for (var child of currentNode.children) {
            this.keepOrAddDataModelNodeIdentifiersRec(child);
         }
      }
   }

   /**
    * Generates "identifier" for the given node name (while removing special characters)
    * @param nodeName name to use in identifier
    * @returns Identifier, in camel case, containing only [A-Za-z0-9] characters
    */
   public generateNodeIdentifier(nodeName: string | undefined): string | undefined {
      if (!nodeName) return undefined;

      var replaced = nodeName.replace(/[^0-9a-zA-Z ]/gi, "_"); // Keep only alphanumeric and spaces. Other characters are replaced by character _. The character _ is later replaced to camelcase
      var camelcased = camelCase(replaced); // generate camelCase string
      return camelcased;
   }

   /**
    * Determines, whether or not the node with the given ID is descendant of the given ancestor node
    * @param ancestor DataModel ancestor node of which to find the descendant node
    * @param descendantId ID of the node to find as an descendant of the ancestor node
    * @returns True if node is descendant of the ancestor, false otherwise
    */
   public isTreeNodeDescendantOfAncestor(ancestor: IDataModelNode, descendantId: string): boolean {
      return this.findTreeNode(ancestor, descendantId) !== null;
   }

   /**
    * Determines, whether or not is the node with given ID descendant of the array node
    * @param rootNode Root node to search the node in
    * @param nodeToFindId ID of the node to determine the array descendancy
    * @returns True if node is descendant of the array node, false otherwise
    */
   public isTreeNodeDescendantOfArray(rootNode: IDataModelNode | null, nodeToFindId: string): boolean {
      var foundNode = this.isTreeNodeDescendantOfArrayRec(rootNode, nodeToFindId, false);
      if (foundNode != null) {
         return foundNode.isDescendantOfArray;
      }

      return false;
   }

   private isTreeNodeDescendantOfArrayRec(
      currentNode: IDataModelNode | null,
      nodeToFindId: string,
      isDescendantOfArray: boolean
   ): { foundNode: IDataModelNode; isDescendantOfArray: boolean } {
      var isDescendantOfArrayLocal = false;
      if (currentNode?.type === DataModelNodeType.Array) {
         isDescendantOfArrayLocal = true;
      }

      if (currentNode?.id === nodeToFindId) {
         return { foundNode: currentNode, isDescendantOfArray: isDescendantOfArray };
      }

      if (currentNode?.children) {
         for (var child of currentNode.children) {
            var foundNode = this.isTreeNodeDescendantOfArrayRec(
               child,
               nodeToFindId,
               isDescendantOfArrayLocal || isDescendantOfArray
            );
            if (foundNode != null) {
               return foundNode;
            }
         }
      }

      return null;
   }

   /**
    * Replaces given node's children subtree with another given node's children subtree
    * @param nodeToReplaceTo Node to replace children nodes to
    * @param nodeToReplaceFrom Node to replace children nodes from
    */
   public replaceNodeChildren(nodeToReplaceTo: IDataModelNode, nodeToReplaceFrom: IDataModelNode) {
      nodeToReplaceTo.children = nodeToReplaceFrom.children;
   }

   /**
    * Compares given @param to node's top level children subtree with another given @param from node's children subtree, adding/removing children of @param to node.
    * Adds new children present in @param from node, which are not present in @param to node.
    * Removes old children present in @param to node, which are no longer present in @param from node.
    * Keeps old children present in @param to node, if children are present both in @param to and @param from nodes.
    * @param from Node to add missing children nodes to
    * @param to Node to add missing children nodes from
    */
   public addNewAndRemoveOldNodeChildren(to: IDataModelNode, from: IDataModelNode) {
      if (!from?.children || !to?.children) return;

      // remove old
      var oldChildIndex = 0;
      for (var oldChildToRemove of to.children) {
         var newChild = from.children.find((n) => n.id === oldChildToRemove.id);
         if (!newChild) {
            // no new child -> remove child
            to.children.splice(oldChildIndex, 1);
         }
         oldChildIndex++;
      }

      // add new
      var newChildIndex = 0;
      for (var newChildToAdd of from.children) {
         var oldChild = to.children.find((o) => o.id === newChildToAdd.id);
         if (!oldChild) {
            // no old child -> add child
            to.children.splice(newChildIndex, 0, newChildToAdd);
         }
         newChildIndex++;
      }
   }

   /**
    * Gets the string path from the root node to the node by given ID, consisting of DataModel node names, as well as list of node names
    * @param rootNode Root node of which to construct the path
    * @param nodeToFind Node to find in the tree, and construct the path to
    * @example { nodePath: "/Train/Engine/Power", nodePathSequence: [ "Train", "Engine", "Power" ] }
    * @returns Path from root node to the given node, as well as sequence of the node names
    */
   public getNodePathSequenceByName(
      rootNode: IDataModelNode | null,
      nodeToFind: IDataModelNode | null
   ): { nodePath: string; nodePathSequence: string[] } | null {
      return this.getNodePathSequence(rootNode, nodeToFind.id, "name");
   }

   /**
    * Gets the string path from the root node to the node by given ID, consisting of DataModel node IDs, as well as list of node IDs
    * @param rootNode Root node of which to construct the path
    * @param nodeToFindId Node ID to find in the tree, and construct the path to
    * @example { nodePath: "/id1/id2/id3", nodePathSequence: [ "id1", "id2", "id3" ] }
    * @returns Path from root node to the given node, as well as sequence of the node IDs
    */
   public getNodePathSequenceById(
      rootNode: IDataModelNode | null,
      nodeToFindId: string
   ): { nodePath: string; nodePathSequence: string[] } | null {
      return this.getNodePathSequence(rootNode, nodeToFindId, "id");
   }

   /**
    * Gets the string path from the root node to the node by given ID, consisting of given DataModel node properties, as well as list of said properties
    * @param rootNode Root node of which to construct the path
    * @param nodeToFindId Node ID to find in the tree, and construct the path to
    * @param propertyOfPath Property to construct the path from
    * @example { nodePath: "/id1/id2/id3", nodePathSequence: [ "id1", "id2", "id3" ] }
    * @returns Path from root node to the given node, as well as sequence of the node names
    */
   private getNodePathSequence(
      rootNode: IDataModelNode | null,
      nodeToFindId: string,
      propertyOfPath: string
   ): { nodePath: string; nodePathSequence: string[] } | null {
      var nodePathSequence = this.getNodePath(rootNode, nodeToFindId, propertyOfPath);
      return {
         nodePath: this.joinNodePath(nodePathSequence),
         nodePathSequence: nodePathSequence,
      };
   }

   /**
    * Gets the string path from the root node to the node by given ID, consisting of DataModel node names
    * @param rootNode Root node of which to construct the path
    * @param nodeToFind Node to find in the tree, and construct the path to
    * @example "/Train/Engine/Power"
    * @returns Path from root node to the given node
    */
   public getNodePathByName(rootNode: IDataModelNode | null, nodeToFind: IDataModelNode | null): string | null {
      return this.joinNodePath(this.getNodePath(rootNode, nodeToFind.id, "name"));
   }

   /**
    * Gets the string path from the root node to the node by given ID, consisting of DataModel node IDs
    * @param rootNode Root node of which to construct the path
    * @param nodeToFind Node to find in the tree, and construct the path to
    * @example "/TrainId/EngineId/PowerId"
    * @returns Path from root node to the given node
    */
   public getNodePathById(rootNode: IDataModelNode, nodeToFind: IDataModelNode): string | null {
      return this.joinNodePath(this.getNodePath(rootNode, nodeToFind.id, "id"));
   }

   private getNodePath(rootNode: IDataModelNode, nodeToFindId: string, propertyOfPath: string | null): string[] | null {
      var path = [];
      if (rootNode && nodeToFindId) {
         var foundPath = this.getNodePathReq(rootNode, nodeToFindId, path, propertyOfPath);
         if (foundPath.isFound) {
            return foundPath.path;
         }
      }

      return null;
   }

   private getNodePathReq(
      currentNode: IDataModelNode,
      nodeToFindId: string,
      path: string[],
      propertyOfPath: string
   ): { path: string[]; isFound: boolean } {
      var currentPath = [...path]; // gotta use deep copy, or else path consists of all traversed nodes of multiple node levels
      if (currentNode) {
         currentPath.push(currentNode[propertyOfPath]);
      }

      if (currentNode.id === nodeToFindId) {
         return { path: currentPath, isFound: true };
      }

      if (currentNode.children) {
         for (let child of currentNode.children) {
            var foundPath = this.getNodePathReq(child, nodeToFindId, currentPath, propertyOfPath);
            if (foundPath.isFound) {
               return foundPath;
            }
         }
      }

      return { path: currentPath, isFound: false };
   }

   private joinNodePath(nodePath: string[] | null, separator: string = "/"): string | null {
      if (nodePath) {
         return nodePath.join(separator);
      }

      return null;
   }

   /**
    * Gathers matched nodes and nodes in their path from root which are not open (expanded in tree)
    * @returns Set of node IDs which are in path of search match results, and are currently closed (collapsed in tree)
    */
   public getSearchMatchedNodesToOpen<TNode extends { id: string | undefined }>(
      dataModelTreeNodes: TNode[],
      openNodeIds: string[],
      treeView: any
   ): string[] {
      var matchedNodes = dataModelTreeNodes.filter((n) => this.isNodeSearchMatched(n));
      var nodesToOpen: string[] = [];
      for (var matchedNode of matchedNodes) {
         var path = TreeviewUtils.getNodePath(matchedNode, treeView);
         if (path.length < 1) {
            throw `Node was not found when trying to get node path. Node ID: '${matchedNode.id}''`;
         }
         nodesToOpen = nodesToOpen.concat(path.filter((n) => !this.isNodeOpen(n, openNodeIds)).map((n) => n.id!));
      }
      return uniq(nodesToOpen);
   }

   /**
    * Determines if given node is marked as search match result
    * @param node Node to determine search mark of
    * @returns True if node is search match result, false othervise
    */
   public isNodeSearchMatched(node: any): boolean {
      return node["searchMatched"] === true;
   }

   /**
    * Determines if given node is in open/expanded state
    * @param node Node to determine open state of
    * @param openNodeIds List of open node IDs
    * @returns True if node is open, false othervise
    */
   public isNodeOpen<TNode extends { id: string | undefined }>(node: TNode, openNodeIds: string[]): boolean {
      return openNodeIds.findIndex((o) => o === node.id) > -1;
   }

   /**
    * Marks all data model tree nodes which are in path of any array node length query with "isPartOfQuery" flag.
    * When query traversal gets to an error state, additionally marks last query node with "isPartOfQueryWithError" flag.
    * @param rootNode DataModel tree node where to begin marking
    * @param vue Vue instance to use for setting the values to objects (when calling from a component, usually pass 'this')
    * @param forceMark Whether or not to force marking of the nodes, when true, skips conditional execution
    * @param nodes Nodes which to use for conditional marking of the nodes. When any provided node is marked with
    * 'isPartOfQuery' flag, re-marking is executed
    */
   public markNodesPartOrArrayLengthQuery(
      rootNode: IDataModelNode,
      vue: Vue,
      forceMark: boolean,
      ...nodes: IDataModelNode[]
   ) {
      var isMarked = forceMark || nodes.some((node) => node["isPartOfQuery"] === true);
      if (isMarked === true) {
         this.unmarkNodesPartOfArrayQuery(rootNode, vue, rootNode); // clear first
         this.markNodesPartOfArrayQuery(rootNode, vue, rootNode); // mark
      }
   }

   private markNodesPartOfArrayQuery(rootNode: IDataModelNode, vue: Vue, node?: IDataModelNode) {
      if (!node) return;

      if (
         node.type === DataModelNodeType.Array &&
         node.arrayFields?.arrayLengthType === DataModelNodeArrayLengthType.Referenced
      ) {
         var queryFields = node.arrayFields.referencedLength;
         this.markNodesByArrayQuery(rootNode, node, queryFields, vue);
      }

      if (node.children) {
         for (var child of node.children) {
            this.markNodesPartOfArrayQuery(rootNode, vue, child);
         }
      }
   }

   private unmarkNodesPartOfArrayQuery(rootNode: IDataModelNode, vue: Vue, node?: IDataModelNode) {
      if (!node) return;

      if (node["isPartOfQuery"] === true) {
         this.unmarkNodeAsPartOfQuery(node, vue);
      }
      if (node["isPartOfQueryWithError"] === true) {
         this.unmarkNodeAsPartOfQueryWithError(node, vue);
      }

      if (node.children) {
         for (var child of node.children) {
            this.unmarkNodesPartOfArrayQuery(rootNode, vue, child);
         }
      }
   }

   /**
    * Marks data model nodes which are in path of given array node length query with "isPartOfQuery" flag.
    * When query traversal gets to an error state, additionally marks last query node with "isPartOfQueryWithError" flag.
    * @param rootNode DataModel tree root node
    * @param node Node from which to begin marking
    * @param queryFields Array length ContentBrick node metadata to use when following query
    * @param vue Vue instance
    */
   private markNodesByArrayQuery(
      rootNode: IDataModelNode,
      node: IDataModelNode,
      queryFields: DataModelContentBrickFieldLink | undefined,
      vue: Vue
   ) {
      if (!rootNode) throw "Root node is required to mark nodes part of Array length query";
      if (!node) throw "Node is required to mark nodes part of Array length query";
      if (!queryFields || !queryFields.nodePath)
         throw "Query fields are required to mark nodes part of Array length query";

      this.markNodeAsPartOfQuery(node, vue); // mark first node

      try {
         // up from provided node to start node
         var levelsUp = queryFields.levelsUpToStartNodeCount; // by how many levels traverse upwards
         var currentNode = node; // start in provided node
         var startNode: IDataModelNode | null = null; // downward query start node to traverse up to
         for (var i = 0; i < levelsUp; i++) {
            startNode = this.findTreeParentNode(rootNode, currentNode.id); // find parent -> go level up
            if (!startNode)
               throw new DataModelNodeQueryError(
                  `Could not find a parent of the node with ID: '${currentNode.id}', identifier '${currentNode.identifier}'`,
                  currentNode
               );

            this.markNodeAsPartOfQuery(startNode, vue); // mark node in path
            currentNode = startNode; // continue in level up
         }

         if (!startNode)
            throw new DataModelNodeQueryError(
               `Could not find a start node for array length query from array node with ID: '${node.id}', identifier '${node.identifier}'`,
               node
            );

         // down from start node to array length CB
         var regex = /(?:\['(?<nodeIdentifier>.+?)'\])+?/gm; // regex to parse downward query identifier segments
         var matchedNodeIdentifiers = this.findMatches(regex, queryFields.nodePath);
         if (!matchedNodeIdentifiers || matchedNodeIdentifiers.length < 1) {
            throw new DataModelNodeQueryError(
               `Could not parse array length downward query from array node with ID: '${node.id}', identifier '${node.identifier}'`,
               startNode
            );
         }
         var currentDownwardNode: IDataModelNode = startNode; // start in start node
         // iterate ['container', 'container2', 'contentBrick']
         for (var matchedNodeIdentifier of matchedNodeIdentifiers) {
            if (!matchedNodeIdentifier.groups)
               throw new DataModelNodeQueryError(
                  `No node identifier groups parsed from query of array node with ID: '${node.id}', identifier '${node.identifier}'`,
                  currentDownwardNode
               );
            var nodeIdentifier = matchedNodeIdentifier.groups["nodeIdentifier"];
            if (!nodeIdentifier)
               throw new DataModelNodeQueryError(
                  `No node identifier parsed from query of array node with ID: '${node.id}', identifier '${node.identifier}'`,
                  currentDownwardNode
               );

            var found = this.findTreeNodeByCondition(currentDownwardNode, (n) => n.identifier === nodeIdentifier, 2); // limit by max depth to get direct child
            if (!found?.foundNode)
               throw new DataModelNodeQueryError(
                  `Could not find a node '${nodeIdentifier}' from current node with ID: '${currentDownwardNode.id}', identifier: '${currentDownwardNode.identifier}'`,
                  currentDownwardNode
               );

            this.markNodeAsPartOfQuery(found.foundNode, vue); // mark in path
            currentDownwardNode = found.foundNode; // continue downwards
         }
      } catch (e) {
         console.error("Could not mark an array length query path correctly", e);
         var error = e as DataModelNodeQueryError;
         if (error && error?.lastCorrectNode) {
            this.markNodeAsPartOfQueryWithError(error.lastCorrectNode, vue);
         }
      }
   }

   /**
    * Finds matches for the provided regex
    * @param regex Regex to use
    * @param str String to test against
    * @param matches Matches to fill
    * @returns Matched tokens, if any
    */
   private findMatches(regex, str, matches: RegExpExecArray[] = []) {
      const res: RegExpExecArray | null = regex.exec(str);
      res && matches.push(res) && this.findMatches(regex, str, matches);
      return matches;
   }

   private markNodeAsPartOfQueryWithError(node: IDataModelNode, vue: Vue) {
      this.markNode(node, "isPartOfQueryWithError", vue);
   }

   private unmarkNodeAsPartOfQueryWithError(node: IDataModelNode, vue: Vue) {
      this.unmarkNode(node, "isPartOfQueryWithError", vue);
   }

   private markNodeAsPartOfQuery(node: IDataModelNode, vue: Vue) {
      this.markNode(node, "isPartOfQuery", vue);
   }

   private unmarkNodeAsPartOfQuery(node: IDataModelNode, vue: Vue) {
      this.unmarkNode(node, "isPartOfQuery", vue);
   }

   private markNode(node: IDataModelNode, property: string, vue: Vue) {
      vue.$set(node, property, true);
   }

   private unmarkNode(node: IDataModelNode, property: string, vue: Vue) {
      vue.$set(node, property, undefined);
   }

   public camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
         if (+match === 0) return "";
         return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
   }

   /**
    * Extends project-like DataModel tree with .children properties, also transforms array nodes
    * ([] -> {}, adds found .$metadata, creates array sub-items 0, 1, 2)
    * @param node DataModel tree to extend
    * @param additionalNodeKeyFilter Additional filter to add to filtering node's properties which should/should not
    * be traversed for recursive extending
    * @returns Extended DataModel tree
    */
   public extendNode(node: any, additionalNodeKeyFilter: (key: string) => boolean = (key: string) => true) {
      if (!node) return;

      if (
         node.$metadata?.type !== ProjectDataModelNodeType.Container &&
         node.$metadata?.type !== ProjectDataModelNodeType.Array &&
         node.$metadata?.type !== ProjectDataModelNodeType.ArrayItem
      ) {
         if (node.$metadata?.type === ProjectDataModelNodeType.ContentBrick) {
            this.setIdProp(node);
            Object.defineProperty(node, "children", {
               value: [],
               configurable: true,
               writable: true,
            }); // define children prop otherwise virtual scroll generates non-property one when scrolling
         }

         return; // non container/array node stop extending
      }

      // generates children prop, in order to be able to render in the tree
      Object.defineProperty(node, "children", {
         value: Object.keys(node)
            .filter((key) => key != "$metadata" && additionalNodeKeyFilter(key))
            .filter((key) => !key.includes("$metadata"))
            .filter((key) => !isArray(node[key]))
            .map((key) => node[key]),
         configurable: true,
         writable: true,
      }); //non-enumerable prop

      this.extendArrayNodes(node);
      this.setIdProp(node);

      if (node.children?.length > 0) {
         node.children.forEach((child, index) => this.extendNode(child)); // recursively process children
      }
   }

   /**
    * Generates node "id" prop, in order to access node from tree (component does not support nested props "$metadata.id" as key)
    * @param node Node to set ID of
    */
   private setIdProp(node: any) {
      if (node.$metadata.id !== undefined) {
         Object.defineProperty(node, "id", {
            value: node.$metadata.id,
            configurable: true,
         });
      }
   }

   /**
    * Processes array node children of the passed node.
    * @summary Transforms array structure into object structure, moves array node metadata, generates array item's metadata.
    * @param node Node to process array nodes of
    */
   private extendArrayNodes(node: any) {
      for (var key of Object.keys(node)
         .filter((key) => !key.includes("$metadata")) // non metadata and array metadata keys
         .filter((key) => isArray(node[key]))) /* array keys */ {
         var arrayProp = { ...node[key] }; // transform array node array to object
         var metadata = node[`${key}_$metadata`]; // get array nodes' metadata

         // iterate through array object keys (actual items of the array)
         for (const [index, [key, value]] of Object.entries(Object.entries(arrayProp))) {
            if (!arrayProp[key].$metadata) {
               // item has no metadata -> create generic ones, in order to allow nodes's rendering (used to render array item's parent node - "0", "1", "2"...)
               var prepend = metadata?.identifier ? metadata.identifier : Guid.create().toString(); // prepend current node's identifier or generate guid -> to keep uniqueness of node keys in view
               var id = `${prepend}_${index.toString()}`;
               Object.defineProperty(arrayProp[key], "$metadata", {
                  value: {
                     id: id, // set ID from current node's name or GUID, concatenated with node sequence number ("Array_0" for first item of array node "Array")
                     name: index.toString(), // name is index of the item
                     type: ProjectDataModelNodeType.ArrayItem, // type is array
                     isArrayItem: true, // flag to denote that the array node is an actual indexed array item: array node -> 0 (child of array), 1,...
                  },
                  configurable: true,
                  writable: true,
               });
               this.setIdProp(arrayProp[key]); // set nested object id prop
            }
         }
         // set metadata directly to the encapsulating array object
         Object.defineProperty(arrayProp, "$metadata", {
            value: metadata,
            configurable: true,
            writable: true,
         });

         this.setIdProp(arrayProp); // set array node id prop

         node.children.push(arrayProp); // add array object to current node's children
      }
   }

   /**
    * Flattens given project-like DataModel tree
    * @param node DataModel tree to flatten
    * @returns Flat sequence of DataModel tree nodes
    */
   public flattenDataModelTree<TNode extends { [key: string]: any }>(
      node: TNode | TNode[] | null | undefined
   ): TNode[] {
      return this.flattenTreeRec(node, null, null);
   }

   /**
    * Flattens given project-like DataModel sub-tree (also traverses array node sub structures)
    * @param node Current node to flatten
    * @param parent Parent node of the current node
    * @param nodeKey Key of the current node, in the nested tree dictionary
    * @returns Flat sequence of DataModel sub-tree nodes
    */
   private flattenTreeRec<TNode extends { [key: string]: any }>(
      node: TNode | TNode[] | null | undefined,
      parent: TNode | TNode[] | null,
      nodeKey: string | null
   ): TNode[] {
      if (!node) return [];

      var isArrayNode = isArray(node); // check if current node is array
      if (!(<TNode>node)?.$metadata && !isArrayNode) return []; // no metadata + is NOT array -> end recursion

      if (isArrayNode && !!parent && !!nodeKey) {
         // could be array node
         var arrayMetadata = parent[`${nodeKey}_$metadata`]; // try to get array node's metadata (via parent, array metadata on same level as array node)
         if (!arrayMetadata || (<DataModelNodeMetaData>arrayMetadata).type !== ProjectDataModelNodeType.Array) {
            return []; // no metadata -> not an array node -> end recursion
         }
         var arrayItemNodes = (<TNode[]>node).flatMap((ai) => this.flattenTreeRec(ai, node, null)); // recursively call on array items
         var arrayNodeObject = parent["children"]
            ? parent["children"].find((ch) => ch.$metadata.id === arrayMetadata.id)
            : null;
         return (<TNode[]>[]).concat(arrayNodeObject ?? []).concat(arrayItemNodes); // return array node + gathered flat children from array items
      }

      return (<TNode[]>[]).concat.apply(
         [node],
         Object.keys(<any>node)
            .filter((key) => !key.includes("$metadata"))
            .map((key) => this.flattenTreeRec(node[key], node, key))
      ); //recursively call .concat on children
   }

   public isReferencePropagated(reference: GeneralDataModelDefinitionNestedReference): boolean {
      let propagatedFlag = reference[this.isPropagatedProperty];
      return (
         propagatedFlag && propagatedFlag instanceof PropagatedGdmReferenceFlag && propagatedFlag.isPropagated === true
      );
   }

   public setReferencePropagated(
      reference: GeneralDataModelDefinitionNestedReference,
      path: string,
      isPropagated: boolean
   ): void {
      let flag: PropagatedGdmReferenceFlag = new PropagatedGdmReferenceFlag(path, isPropagated);
      reference[this.isPropagatedProperty] = flag;
   }

   public isReferenceDisabled(reference: GeneralDataModelDefinitionNestedReference): boolean {
      let disabledFlags = reference[this.isDisabledProperty];
      if (
         disabledFlags && // has property
         disabledFlags instanceof Map && // map
         disabledFlags.size > 0 && // with entries
         [...disabledFlags.values()].find((v) => v === true) !== undefined // any true
      ) {
         return true;
      }

      return false;
   }

   public setReferenceDisabled(
      reference: GeneralDataModelDefinitionNestedReference,
      path: string,
      isDisabled: boolean
   ): void {
      let disabledFlags = reference[this.isDisabledProperty];
      disabledFlags ??= new Map<string, boolean>(); // init if undefined
      if (!(disabledFlags instanceof Map)) {
         let err = `Expected object to be instance of: 'Map'. Type of object: '${typeof disabledFlags}'. Reference path '${
            reference.nestedReferenceCodePath
         }'`;
         console.error(err, disabledFlags);
         throw err;
      }

      if (isDisabled) {
         // true -> set map entry
         disabledFlags.set(path, isDisabled);
      } else {
         // false -> remove map entry
         disabledFlags.delete(path);
      }
   }

   public getPathsCausingReferenceDisabledText(reference: GeneralDataModelDefinitionNestedReference): string {
      let itemPaths = this.getPathsCausingReferenceDisabled(reference);
      if (itemPaths.length < 1) {
         // no paths -> default
         return '"Unknown paths"';
      }

      // join paths as string
      return `"${itemPaths.join('", "')}"`;
   }

   private getPathsCausingReferenceDisabled(reference: GeneralDataModelDefinitionNestedReference): string[] {
      let disabledFlags = reference[this.isDisabledProperty];
      if (!disabledFlags || !(disabledFlags instanceof Map)) {
         // no map -> no paths
         return [];
      }

      // paths are keys
      return [...disabledFlags.keys()];
   }

   /**
    * Determines insert index of a child node to be added to the parent node's children, by implicit order of node types
    * @param parent Parent node where the child nodes is to be added
    * @param nodeToInsert Child node to insert into the parent node
    * @returns Integer index in parent node's children array, in which place child node should be added
    */
   public getNodeInsertIndex(
      parent: IDataModelNode | IProjectDataModelNode,
      nodeToInsert: IDataModelNode | IProjectDataModelNode
   ): number {
      if (!parent.children?.length) {
         return 0;
      }

      const nodeToInsertOrder = this.getNodeInsertOrder(nodeToInsert);
      const children = parent.children as (IDataModelNode | IProjectDataModelNode)[];

      return findLastIndex(children, (child) => this.getNodeInsertOrder(child) <= nodeToInsertOrder) + 1;
   }

   private getNodeInsertOrder(node: IDataModelNode | IProjectDataModelNode): number {
      const lastCbType = Math.max(
         ...Object.keys(ContentBrickType)
            .filter((key) => !isNaN(Number(key)))
            .map((key) => ContentBrickType[key as keyof typeof ContentBrickType] as number)
      );

      switch (node.type) {
         case DataModelNodeType.ContentBrick:
            return node.contentBrickType;
         case DataModelNodeType.Container:
            return lastCbType + 1;
         case DataModelNodeType.Array:
            return lastCbType + 2;
         default:
            return Number.MAX_SAFE_INTEGER;
      }
   }
}
export default new DataModelUtils();
